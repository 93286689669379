
  import { AssignMemberRequest, Role, UserInfoInterface } from '@/interface/BaseInterface'
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { assign } from 'lodash'
  import getApi from '@/api'

  const courseMemberApi = getApi('course_member')
  const memberApi = getApi('member')

  @Component
  export default class AssignMember extends Vue {
    @Prop({ required: true, type: Array }) roles!: Role[]

    users: UserInfoInterface[] = []

    loading = false

    timeOut: any = null

    data: AssignMemberRequest = {
      role: '',
      ids: []
    }

    reset() {
      this.data = {
        role: '',
        ids: []
      }
    }
    handleOpen() {
      this.reset()
    }

    handleClose() {
      this.reset()
    }

    async searchUser(query: any) {
      try {
        this.loading = true
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(async () => {
          const data = await memberApi.all({
            page: 1,
            perPage: 100,
            searchText: query
          })
          this.users = data.items
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    }

    async handleSubmit() {
      try {
        // @ts-ignore
        this.$refs.formActive.validate(async (value: boolean) => {
          if (value) {
            const result = assign(this.data)
            const data = await courseMemberApi.assignMembers(this.courseId, result)
            this.$emit('submit', data)
            this.$message.success(this.$t('createSuccess') as string)
            return Promise.resolve()
          } else {
            return Promise.reject(false)
          }
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
