
  import { Component, Vue } from 'vue-property-decorator'
  import { Role, UserInfoInterface, MetaInterface } from '@/interface/BaseInterface'
  import getApi from '@/api'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'
  import AssignMember from '../components/member/AssignMember.vue'
  import MemberApi from '@/modules/member/api/memberApi'

  const courseMemberApi = getApi('course_member')
  const memberApi: MemberApi = getApi('member')
  const tBase = namespace('base')
  @Component({
    components: { AssignMember }
  })
  export default class CourseMember extends Vue {
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void
    data: UserInfoInterface[] = []
    roles: Role[] = []

    meta: MetaInterface = {
      page: 1,
      perPage: 10,
      total: 1
    }
    filter = {
      searchText: ''
    }
    isLoading = false

    async init() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        const result = await courseMemberApi.list({
          courseId: this.courseId,
          page: this.meta.page,
          perPage: this.meta.perPage,
          searchText: this.filter.searchText
        })
        this.data = result.items
        this.meta = result.meta
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    getCourseId() {
      return this.$route.params.id
    }

    async handleRemove(value: UserInfoInterface) {
      try {
        await courseMemberApi.detachMember(this.courseId, value._id)
        this.$message.success(this.$t('success') as string)
        this.init()
      } catch (error) {
        console.log(error)
      }
    }

    handleFilter(filter: any) {
      this.filter.searchText = filter.search
      this.meta = {
        page: 1,
        perPage: 10,
        total: 100
      }
      this.init()
    }

    handleSizeChange(size: number) {
      this.meta.perPage = size
      this.meta.page = 1
      this.init()
    }

    handlePageChange(page: number) {
      this.meta.page = page
      this.init()
    }

    handleAssignMember() {
      this.setOpenPopup({
        module: 'assignMember',
        isOpen: true
      })
    }

    handleAssignMemberSubmitted() {
      this.setOpenPopup({
        module: 'assignMember',
        isOpen: false
      })
      this.init()
    }

    async getRoles() {
      this.roles = await memberApi.getRoles('course')
    }

    created() {
      this.getRoles()
      this.init()
    }
  }
